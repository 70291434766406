import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Image,
  message,
  Progress,
  Row,
  Select,
  Skeleton,
  Switch,
  Tag,
  theme,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import Table2 from "../../../components/tables/table2";
import { AddCommas, ConvertParams, FormatDateRange } from "../../../config";
import { DymmyImage } from "../../../config/fakeData";
import { CardViewWrapper, Wrapper } from "./style";

const { Text, Title, Paragraph } = Typography;
const { Panel } = Collapse;

const CardView = ({
  title,
  setSelectedCard,
  list,
  selectedCard,
  cardRows,
  summaryLoading,
  getTable,
}) => {
  const { token } = theme.useToken();
  const [expanded, setExpanded] = useState(false);

  const {
    revenue,
    total_unit,
    filter_name,
    organic_unit,
    profit_per_unit,
    roi,
    ppc_per_unit,
    acos,
    sessions,
    conversion_rate,
    end_date,
    filter_key,
    sd_unit,
    total_profit,
    sp_unit,
    refund_unit,
    start_date,
  } = list?.[title?.indexKey] || {};

  console.log(filter_key === "today", "list?.[title?.indexKey]");

  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const panelStyle = {
    background: "transparent",
    borderRadius: token.borderRadiusLG,
    border: "none",
    width: "100%",
  };

  const loading = summaryLoading;
  return (
    <CardViewWrapper span={24}>
      <Card
        title={
          <div
            style={{
              background: title?.color,
              paddingInline: "20px",
              paddingTop: "10px",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {loading ? (
                <>
                  {" "}
                  <Skeleton.Input className="mb-1" size="large" active />
                </>
              ) : (
                <div>
                  <Text style={{ color: "#ffffff", fontSize: "20px" }}>
                    {filter_name || "-"}
                  </Text>{" "}
                  <br />
                  <span
                    style={{
                      color: "#ffffff",
                      fontWeight: 400,
                      fontSize: "13px",
                    }}
                  >
                    {FormatDateRange(start_date, end_date)}
                  </span>
                </div>
              )}

              {filter_key === "today" ? (
                <> </>
              ) : loading ? (
                <Skeleton.Button className="mb-1" active />
              ) : (
                <Text
                  className="values-text"
                  style={{ color: "#28a745", fontSize: "16px" }}
                >
                  15.8% ↑{" "}
                  <p style={{ color: "#fff", fontSize: "12px" }}>Last year</p>
                </Text>
              )}
            </div>
          </div>
        }
        hoverable
        headStyle={{
          borderRadius: "12px",
          boxShadow: `${
            selectedCard?.title === title?.title
              ? `${title?.color}7a 0px 7px 29px 0px`
              : ""
          }`,
          borderBottom: "none",
          border: `${
            selectedCard?.title === title?.title
              ? `3px solid ${title?.color}`
              : "3px solid #fff"
          } `,
        }}
        style={{
          borderRadius: "8px",
        }}
        onClick={(e) => {
          getTable({
            start_date: start_date,
            end_date: end_date,
          });
          setSelectedCard({
            color: title?.color,
            title: filter_name,
            date: FormatDateRange(start_date, end_date),
          });
        }}
        bodyStyle={{
          marginTop: "10px",
          borderRadius: "12px",
          border: `${
            selectedCard?.title === title?.title
              ? "3px solid #32a4ec"
              : "3px solid #fff"
          } `,
          boxShadow: `${
            selectedCard?.title === title?.title
              ? "#32a4ec57 0px 7px 29px 0px"
              : ""
          }`,
        }}
      >
        {loading ? (
          Array(4)
            .fill(0)
            .map((_, index) => (
              <Row key={index}>
                <Col flex="auto">
                  <Skeleton.Input className="mb-1" size="large" active />
                </Col>
                <Col flex="none">
                  <Skeleton.Button className="mb-1" size="large" active />
                </Col>
              </Row>
            ))
        ) : (
          <>
            <Row justify="space-between">
              <Col>
                <Text strong>Revenue</Text>
              </Col>
              <Col>
                <Text strong style={{ color: "#004085", fontSize: "20px" }}>
                  {AddCommas(revenue || 0)} €
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>Units</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>{AddCommas(total_unit || 0)}</Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />
                <Row justify="space-between">
                  <Col>
                    <Text>Organic</Text>
                  </Col>
                  <Col>
                    <Text strong style={{ color: "#004085" }}>
                      {AddCommas(organic_unit || 0)}
                    </Text>
                  </Col>
                </Row>
                <Divider className="my-2" />
                <Row justify="space-between">
                  <Col>
                    <Text>Sponsored Products</Text>
                  </Col>
                  <Col>
                    <Text strong style={{ color: "#004085" }}>
                      {AddCommas(sp_unit || 0)}
                    </Text>
                  </Col>
                </Row>
                <Divider className="my-2" />
                <Row justify="space-between">
                  <Col>
                    <Text>Sponsored Display</Text>
                  </Col>
                  <Col>
                    <Text strong style={{ color: "#004085" }}>
                      {AddCommas(sd_unit || 0)}
                    </Text>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            <Row justify="space-between">
              <Col span={12}>
                <Text>Refund</Text>
              </Col>
              <Col span={12}>
                <Text
                  strong
                  style={{
                    color: "#004085",

                    marginLeft: "17px",
                  }}
                >
                  {AddCommas(refund_unit || 0)}
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>Costs</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>$0</Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />
                {cardRows.map((item, index) => (
                  <div key={index}>
                    <Row justify="space-between">
                      <Col style={{ maxWidth: "100px" }}>
                        <Text>{item.name}</Text>
                      </Col>
                      <Col
                        span={16}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <div
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text style={{ color: item.color || "inherit" }}>
                            $
                            {AddCommas(
                              list?.[title?.indexKey]?.[item.value] || 0
                            )}
                          </Text>
                          {item.percentage && (
                            <Text style={{ color: item.color || "inherit" }}>
                              {item.percentage}
                            </Text>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Divider className="my-2" />
                  </div>
                ))}
              </Panel>
            </Collapse>

            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            <Row justify="space-between" align="middle">
              <Col>
                <Text strong style={{ color: "#28a745" }}>
                  Profit
                </Text>
              </Col>
              <Col>
                <Text strong style={{ color: "#28a745" }}>
                  {AddCommas(total_profit || 0)} €
                </Text>
              </Col>
              <Col>
                <Text style={{ color: "#28a745" }}>10,4%</Text>
              </Col>
              <Col>
                <Text
                  onClick={toggleExpand}
                  style={{ cursor: "pointer", color: "#28a745" }}
                >
                  {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </Text>
              </Col>
            </Row>

            {expanded && (
              <div
                style={{
                  marginTop: 10,
                  backgroundColor: "#d5fbddcc",
                  padding: 10,
                }}
              >
                <Row justify="space-between">
                  <Col span={8}>
                    <Text>Profit per unit</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(profit_per_unit || 0)} €</Text>
                  </Col>
                  <Col span={8}>
                    <Text>ROI</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(roi || 0)}%</Text>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={8}>
                    <Text>PPC per unit</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(ppc_per_unit || 0)} €</Text>
                  </Col>
                  <Col span={8}>
                    <Text>ACOS</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(acos || 0)}%</Text>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={8}>
                    <Text>Sessions</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(sessions || 0)}</Text>
                  </Col>
                  <Col span={8}>
                    <Text>Conversion</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(conversion_rate || 0)}%</Text>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </Card>
    </CardViewWrapper>
  );
};
export default function Dashboard(props) {
  const { userData } = props;
  const [filterLoading, setFilterLoading] = useState(true);
  const [filterData, setFilterData] = useState({});
  const [summaryList, setSummaryList] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({
    filterCard: null,
  });

  const [productList, setProductList] = useState([]);
  const [productLoading, setProductLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState({
    title: null,
    color: null,
    date: null,
  });

  const DashboardProductsAction = async (data) => {
    setProductLoading(true);
    const response = await MakeApiCall(
      `dashboard/products${ConvertParams(data)}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      console.log(response, "response");

      setProductList(response?.data || []);
      setProductLoading(false);
    } else {
      setProductList([]);
      message.warning(response?.message);
      setProductLoading(false);
    }
  };
  const cardDetails = {
    datefilter1: [
      {
        cardTitle: [
          {
            color: "#601A3E",

            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#311465",
            indexKey: 1,
          },
          {
            color: "#C9A2C9",
            indexKey: 4,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#4B0076",
            indexKey: 2,
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    datefilter2: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#311465",
            indexKey: 1,
          },
          {
            indexKey: 4,
            color: "#C9A2C9",
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#4B0076",
            indexKey: 2,
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    datefilter3: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#311465",
            indexKey: 1,
          },
          {
            color: "#C9A2C9",
            indexKey: 4,
          },
        ],
      },
      {
        cardTitle: [
          {
            indexKey: 2,
            color: "#4B0076",
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    datefilter4: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            indexKey: 1,
            color: "#311465",
          },
          {
            color: "#C9A2C9",
            indexKey: 4,
          },
        ],
      },
      {
        cardTitle: [
          {
            indexKey: 2,
            color: "#4B0076",
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
  };
  const DashboardSummaryAction = async (data) => {
    setSummaryLoading(true);
    const response = await MakeApiCall(
      `dashboard/summary${ConvertParams(data)}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      if (response?.data?.length > 0) {
        setProductLoading(true);
        DashboardProductsAction({
          start_date: response?.data?.[0]?.start_date,
          end_date: response?.data?.[0]?.end_date,
        });
        setSelectedFilters((prev) => {
          setSelectedCard({
            title: response?.data?.[0]?.filter_name,
            color: cardDetails?.[prev?.filterCard]?.[0]?.cardTitle?.[0]?.color,
            date: FormatDateRange(
              response?.data?.[0]?.start_date,
              response?.data?.[0]?.end_date
            ),
          });

          return prev;
        });
      }
      setSummaryList(response?.data || []);
      setSummaryLoading(false);
    } else {
      message.warning(response?.message);
      setSummaryLoading(false);
    }
  };

  const getDashboardFilterAction = async () => {
    setFilterLoading(true);
    const response = await MakeApiCall(
      `dashboard/get-filter`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      const obj =
        Object.entries(response?.data || {})?.map(([key, value]) => ({
          label: filterTitle?.[key],
          value: key,
        })) || {};
      setFilterData(obj);
      if (obj?.length > 0) {
        setSelectedFilters({ filterCard: obj?.[0]?.value });
        setSummaryLoading(true);
        DashboardSummaryAction({ date_filter: obj?.[0]?.value });
      } else {
        setSummaryLoading(false);
      }

      setFilterLoading(false);
    } else {
      message.warning(response?.message);
      setFilterLoading(false);
    }
  };

  useEffect(() => {
    getDashboardFilterAction();
    return () => {};
  }, []);

  const cardRows = [
    {
      name: "Cost of Goods",
      value: "cogs",
      percentage: "19.3%",
    },
    {
      name: "Amazon Commission",
      value: "commission",
      percentage: "15.0%",
    },
    {
      name: "Shipping",
      value: "shipping",
      percentage: "20.0%",
    },
    {
      name: "PPC",
      value: "ppc",
      percentage: "16.0%",
      color: "red",
    },
    {
      name: "Storage",
      value: "storage",
      percentage: "1.8%",
    },
    {
      name: "Refund",
      value: "refund",
      percentage: "2.2%",
    },
    {
      name: "Other",
      value: "other",
      percentage: "0.9%",
    },
    {
      name: "VAT",
      value: "vat",
      percentage: null,
    },
  ];

  const columns = [
    {
      title: "Product",

      width: 400,
      render: (text, row) => (
        <div
          className="d-flex align-items-center"
          style={{
            background: "#f0f5ff",
            padding: "10px",
            borderRadius: "12px",
          }}
        >
          <Image
            style={{ borderRadius: "18px" }}
            src={row?.image_url || DymmyImage}
            width={75}
            height={75}
            onError={(e) => {
              e.target.src = DymmyImage;
            }}
          />
          <div className="ms-3">
            <div className="d-flex">
              <Tag color="#AF69ED" bordered={false}>
                {row?.asin || "-"}
              </Tag>
              <Tag color="#C8A2C9" bordered={false}>
                {row?.sku || "-"}
              </Tag>
            </div>
            <div
              className="my-2"
              style={{
                width: "350px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
              }}
            >
              {row?.product_name || "-"}
            </div>
            <div>
              <Tag color="#957A81" bordered={false}>
                ${row?.price}
                {row?.product_type ? ` - ${row?.product_type}` : ""}
              </Tag>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Units Sold",
      dataIndex: "unit_sold",
      key: "unit_sold",
    },
    {
      title: "Refunds",
      dataIndex: "refund",
      key: "refund",
      render: (text) => `$${text?.toLocaleString()}`, // Format refunds as currency
    },
    {
      title: "Sales",
      dataIndex: "sales",
      key: "sales",
      render: (text) => `$${text?.toLocaleString()}`, // Format sales as currency
    },
    {
      title: "Ads",
      dataIndex: "ads",
      key: "ads",
      render: (text) => `$${text?.toLocaleString()}`, // Format ads as currency
    },
    {
      title: "Net Profit",
      dataIndex: "net_profit",
      key: "net_profit",
      render: (text) => `$${text?.toLocaleString()}`, // Format net profit as currency
    },
    {
      title: "Margin",
      dataIndex: "margin",
      key: "margin",
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
    },
    {
      title: "BSR",
      dataIndex: "bsr",
      key: "bsr",
    },
    {
      title: "Info",
      dataIndex: "info",
      key: "info",
      render: (text) => <Tag>{text || "-"}</Tag>,
    },
  ];

  const filterTitle = {
    datefilter1:
      "Today / Yesterday / 2 days ago / Last 7 days / Last 14 days / Last 30 days",
    datefilter2:
      "Today / Yesterday / 2 days ago / This month / Last month / 2 month ago",
    datefilter3:
      "This month / Last month / 2 month ago / 3 month ago / 4 month ago / 5 month ago",
    datefilter4:
      "This month / Last month / 2 month ago / This year / Last year / 2 years ago",
  };

  return (
    <Wrapper className="fadeInUp">
      <div className="mb-3 prosess-card">
        <Row className="d-flex justify-content-between" gutter={[16, 16]}>
          <Col
            className="d-flex justify-content-start align-items-center"
            // span={8}
            flex={"none"}
          >
            <Typography>
              <Title level={4}>
                Monthly profit goal:{" "}
                <Icon
                  style={{ position: "relative", top: "3px" }}
                  icon="material-symbols:info"
                />
              </Title>

              <Paragraph className="mb-0">
                <small>*</small>
                <span style={{ fontSize: "0.8rem" }}>
                  Average Profit Over the Last 12 Month
                </span>
              </Paragraph>
            </Typography>
          </Col>
          <Col className="d-flex align-items-center " flex={"auto"}>
            <div className="d-grid px-10" style={{ width: "100%" }}>
              <Progress
                percent={63}
                percentPosition={{
                  align: "end",
                  type: "inner",
                }}
                size={["100%", 20]}
                strokeColor="#2e9520"
              />
              <div className="d-flex justify-content-center align-items-center mt-2">
                <span>2,268€</span>&nbsp;<b>/</b>&nbsp;
                <b>3,600€</b>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-end align-items-center"
            // span={4}
            flex={"none"}
          >
            <Button style={{ borderRadius: "7px" }} type="primary">
              Analyse
            </Button>
          </Col>
        </Row>
      </div>
      <div className="card mb-3" style={{ borderRadius: "12px" }}>
        <div className="card-header d-flex align-items-center justify-content-end">
          {/* <div className="card-title"></div> */}
          <div className="card-toolbar d-flex align-items-center justify-content-end">
            <span style={{ fontSize: "14px", fontWeight: "500" }}>
              {" "}
              Include Expenses:
            </span>
            <Switch
              className="ms-3"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
            <div
              className="ms-3"
              style={{ border: "1px solid #bfbfbf", borderRadius: "30px" }}
            >
              <Select
                options={filterData}
                loading={filterLoading}
                value={selectedFilters?.filterCard}
                onChange={(e) => {
                  setSelectedFilters({
                    ...selectedFilters,
                    filterCard: e,
                  });
                }}
                className="ms-3"
                variant="borderless"
              />
            </div>
            <Select className="ms-3" placeholder="Select Marketplace" />
          </div>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        {cardDetails?.[selectedFilters?.filterCard]?.map((d, i) => (
          <Col span={8} key={i}>
            <Row gutter={[16, 16]}>
              {d?.cardTitle?.map((d) => (
                <CardView
                  summaryLoading={summaryLoading}
                  setSelectedCard={setSelectedCard}
                  title={d}
                  list={summaryList}
                  getTable={DashboardProductsAction}
                  selectedCard={selectedCard}
                  cardRows={cardRows}
                />
              ))}
            </Row>
          </Col>
        ))}
      </Row>
      <div className="card p-5 pt-2 mt-5">
        <div className="card-header d-flex align-items-center mb-2">
          <div className="card-title">
            {selectedCard?.title || "-"} &nbsp;
            <Tag color={selectedCard?.color}>{selectedCard?.date || "-"}</Tag>
          </div>
          <div className="card-toolbar">
            <Text
              className="values-text"
              style={{ color: "#28a745", fontSize: "16px" }}
            >
              15.8% ↑{" "}
              <p style={{ color: "#000", fontSize: "12px" }}>Last year</p>
            </Text>
          </div>
        </div>
        <Table2
          dataSource={productList}
          columns={columns}
          scroll={{ x: "max-content" }}
          loading={productLoading}
          pagination={false} // You can enable pagination if needed
        />
      </div>
    </Wrapper>
  );
}
