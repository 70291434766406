import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddBTN } from "../../../components/button";
import { secondaryColor, thirdColor } from "../../../config";
import { fakeImage } from "../../../config/fakeData";
import { FindMenus } from "./menus";
import { Wrapper } from "./style";

export default function Sidebar({
  collapsed,
  user,
  type,
  location,
  setData,
  handleLogout,
  setCollapsed,
}) {
  const [current, setCurrent] = useState(location?.pathname?.replace("/", ""));
  const { email, name } = user?.user?.user_data;
  const navigate = useNavigate();

  useEffect(() => {
    setCurrent(location?.pathname?.replace("/", ""));
  }, [location]);

  return (
    <Wrapper
      className={`slideInLeft `}
      width={280}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div
        className={`d-flex align-items-center justify-content-between ${
          collapsed ? "sidebar-collapsed" : ""
        }`}
      >
        <div className="demo-logo-vertical shimmer d-flex align-items-center justify-content-center">
          <h3>LOGO</h3>
          {/* {!collapsed && (
          <>
            <img
              style={{ width: "30px" }}
              className=" me-3"
              alt="Logo"
              src={"/assets/favicon.png"}
            />
          </>
        )}
        <img
          style={{ width: collapsed ? "30px" : "100px" }}
          alt="Logo"
          src={collapsed ? "/assets/favicon.png" : "/assets/full-logo.svg"}
        /> */}
        </div>
        <div
          style={{
            transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
          }}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
          className="d-flex align-items-center justify-content-center sidebar-expand"
        >
          <Icon
            width={10}
            icon={"weui:arrow-filled"}
            style={{ color: "#FFF" }}
          />
        </div>
      </div>
      <div
        className="sidebar-view hover-scroll"
        // style={{ marginTop: collapsed ? "65px" : 0, }}
      >
        <Menu
          className=" mt-5"
          selectedKeys={[current]}
          onClick={(e) => {
            setCurrent(e?.key);
          }}
          theme="light"
          defaultOpenKeys={["inventory"]}
          style={{
            position: "relative",
            right: collapsed ? "0px" : "0px",
            top: collapsed ? "20px" : "0px",
          }}
          mode="inline"
          items={FindMenus(type)}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Button onClick={handleLogout} size="large" type="dashed">
          <Icon width={25} icon="solar:logout-bold-duotone" />
          {collapsed ? "" : "Logout"}
        </Button>
      </div>
    </Wrapper>
  );
}
