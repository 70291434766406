import {
  Card,
  Select,
  Row,
  Col,
  Typography,
  Switch,
  Button,
  Input,
  Image,
} from "antd";
import React, { useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";

const { Option } = Select;
const { Text } = Typography;

const UniversalSettings = ({ title }) => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const handleSwitchChange = (checked) => {
    setIsSwitchOn(checked);
  };

  return (
    <div className="card mt-5 w-300px">
      <div className="card-header d-flex align-items-center justify-content-between">
        <div className="mt-4">
          <Image
            style={{ borderRadius: "50px" }}
            src={
              "https://i.etsystatic.com/24287597/r/il/023bd8/5946496414/il_794xN.5946496414_o8xd.jpg"
            }
            width={45}
            height={45}
          />
        </div>
        <div className="mt-4">
          <h3 style={{ color: "#777b81", marginRight: "10px" }}>{title}</h3>
        </div>
        <div className="mt-2">
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={handleSwitchChange}
            checked={isSwitchOn}
          />
        </div>
      </div>
      <div className="card-body p-5">
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            <Text strong>PPC</Text>
          </Col>
          <Col span={12}>
            <Select defaultValue="10%" style={{ width: "100%" }}>
              <Option value="10%">10%</Option>
              <Option value="15%">15%</Option>
              <Option value="20%">20%</Option>
            </Select>
          </Col>

          <Col span={12}>
            <Text>Storage cost</Text>
          </Col>
          <Col span={12}>
            <Select defaultValue="4%" style={{ width: "100%" }}>
              <Option value="4%">4%</Option>
              <Option value="5%">5%</Option>
              <Option value="6%">6%</Option>
            </Select>
          </Col>

          <Col span={12}>
            <Text>Refund</Text>
          </Col>
          <Col span={12}>
            <Select defaultValue="3%" style={{ width: "100%" }}>
              <Option value="3%">3%</Option>
              <Option value="4%">4%</Option>
              <Option value="5%">5%</Option>
            </Select>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default function SalesControlling() {
  const cardsList = [
    "Universal setting",
    "ASIN: B00JDk0X4Q",
    "ASIN: B00JDk0X4Q",
    "ASIN: B00JDk0X4Q",
  ];

  return (
    <>
      <div className="card fadeInUp">
        <div className="card-header d-flex align-items-center justify-content-between">
          {/* Title aligned to the left */}
          <div className="card-title d-flex align-items-center">
            Sales Controlling
          </div>

          {/* Input field and button centered */}
          <div className="d-flex align-items-center justify-content-center">
            <Input
              className="w-300px ms-3"
              defaultValue={3600}
              prefix={
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.9rem",
                  }}
                >
                  Monthly Profit Goal :
                </span>
              }
              suffix={
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "0.9rem",
                  }}
                >
                  €
                </span>
              }
              style={{
                fontSize: "0.95rem",
                fontWeight: 500,
              }}
              size="middle"
            />

            <Button className="ms-3" type="primary">
              Save
              {/* <Icon icon="ic:outline-plus" /> */}
            </Button>
          </div>

          {/* Select Marketplace aligned to the right */}
          <div className="card-toolbar d-flex align-items-center">
            <Select className="ms-3" placeholder="Select Marketplace" />
            <Button className="ms-3" type="primary">
              <Icon icon="ic:outline-plus" />
            </Button>
          </div>
        </div>
      </div>

      <div
        className="d-flex flex-wrap justify-content-evenly fadeInUp "
        style={{ columnGap: "20px" }}
      >
        {cardsList?.map((d, index) => (
          <UniversalSettings key={index} title={d} />
        ))}
      </div>
    </>
  );
}
