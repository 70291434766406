export const UserMenus = [
  {
    label: "Dashboard",
    key: "dashboard",
    icon: "material-symbols:dashboard-outline",
  },
  {
    label: "Products",
    key: "products",
    icon: "ant-design:product-filled",
  },
  {
    label: "Expenses",
    key: "expenses",
    icon: "icon-park-outline:expenses-one",
  },
  {
    label: "Sales Controlling",
    key: "sales-controlling",
    icon: "carbon:sales-ops",
  },
  {
    label: "Report",
    key: "reports",
    icon: "iconoir:reports-solid",
  },
  {
    label: "LTV",
    key: "ltv",
    icon: "tdesign:user-time",
  },
  {
    label: "Goal",
    key: "goal",
    icon: "lucide:goal",
  },
  {
    label: "Product Profit",
    key: "product-profit",
    icon: "fluent-mdl2:product-variant",
  },
  {
    label: "Applications Logs",
    key: "applications-logs",
    icon: "oui:app-ml",
    children: [
      {
        key: "central-logs",
        label: "Central Logs",
      },
      {
        key: "system-logs",
        label: "System Logs",
      },
      {
        key: "user-scheduler",
        label: "User Scheduler",
      },
    ],
  },
];
