import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Card,
  Col,
  Collapse,
  Divider,
  InputNumber,
  Progress,
  Row,
  Typography,
} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ArrowUpOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Wrapper } from "./style";
import { primaryColor, secondaryColor } from "../../../config";

const { Title, Paragraph, Text } = Typography;

const Goal = () => {
  const data = [
    ["January", 10],
    ["February", 20],
    ["March", 15],
    ["April", 25],
    ["May", 30],
    ["June", 18],
    ["July", 22],
    ["August", 35],
    ["September", 28],
    ["October", 17],
    ["November", 23],
    ["December", 19],
  ];
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      //   text: 'Monthly Data for a Year'
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          distance: 30, // Distance from the pie slice to the label
          format: "{point.name}: {point.y}", // Format of the label
        },
        showInLegend: true, // Show the data in the legend
      },
    },
    series: [
      {
        name: "Value",
        data: data,
      },
    ],
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex align-items-center justify-content-center">
          More <Icon icon="hugeicons:unfold-more" />
        </div>
      ),
      children: (
        <div>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
      ),
      showArrow: false,
    },
  ];

  const CardData = [
    { title: "Revenue", value: "272.160 €", percent: null },
    { title: "PPC", value: "29.807 €", percent: "10,9%" },
    { title: "Cost of Goods", value: "33.807 €", percent: "14,1%" },
    { title: "Storage", value: "8.164 €", percent: "3,6%" },
    { title: "Profit", value: "27.216 €", percent: "10,0%" },
  ];

  const [ppc, setPpc] = useState(10.0);

  const monthlyProfit = 2268;
  const goalProfit = 2484;
  const ppcSavings = 216; // Example value based on PPC reduction
  const progressPercentage = (monthlyProfit / goalProfit) * 100;

  return (
    <Wrapper className="fadeInUp">
      <div className="mb-3 prosess-card">
        <Row className="d-flex justify-content-between" gutter={[16, 16]}>
          <Col
            className="d-flex justify-content-start align-items-center"
            span={12}
          >
            <Typography>
              <Title level={4}>
                Monthly profit goal:{" "}
                <Icon
                  style={{ position: "relative", top: "3px" }}
                  icon="material-symbols:info"
                />
              </Title>

              <Paragraph className="mb-0">
                <small>*</small>Average Profit Over the Last 12 Month
              </Paragraph>
            </Typography>
          </Col>
          <Col
            className="d-flex justify-content-center align-items-center"
            span={12}
          >
            <div className="d-grid">
              <Progress
                percent={63}
                percentPosition={{
                  align: "end",
                  type: "inner",
                }}
                size={{ width: 600, height: 30 }}
                // size={[500, 30]}
                strokeColor="#2e9520"
              />
              <div className="d-flex justify-content-center align-items-center mt-2">
                <span>2,268€</span>&nbsp;<b>/</b>&nbsp;
                <b>3,600€</b>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <Divider className="mt-1 mb-0" />
          </Col>

          <Col className="pb-1" span={24}>
            <Collapse bordered={false} items={items} />
          </Col>
        </Row>
      </div>
      <Row className="mb-3" gutter={[16, 16]} justify="center" align="stretch">
        {CardData.map((item, index) => (
          <Col key={index} flex="auto">
            <Card className={`custom-card ${index === 0 ? "first-card" : ""}`}>
              <div className="card-value">{item.value}</div>
              <div className="card-title">{item.title}</div>
              {item.percent && (
                <div className="card-percent">{item.percent}</div>
              )}
            </Card>
          </Col>
        ))}
      </Row>
      <Card
        title={
          <Text style={{ color: secondaryColor, fontSize: 18 }}>Forecast</Text>
        }
        bordered={false}
        className="form-card"
        style={{
          border: `2px dashed ${secondaryColor}`,
          backgroundColor: `${secondaryColor}09`,
          borderRadius: "12px",
          padding: "0px 16px",
        }}
      >
        <Row gutter={16} align="middle">
          <Col span={12}>
            <Text strong style={{ fontSize: 16, color: secondaryColor }}>
              Cost Reduce to achieve the goal:
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Progress
              percent={progressPercentage}
              status="active"
              strokeColor={primaryColor}
              showInfo={false}
              size={{ width: "100%", height: 30 }}
              style={{ width: "100%", marginRight: "10px" }}
            />
            <Text style={{ fontSize: 16 }}>{`${Math.round(
              progressPercentage
            )}%`}</Text>
          </Col>
        </Row>

        <Divider className="my-2" />

        {/* Inputs Section */}
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={6}>
            <Text> Selling price </Text>
            <InputNumber
              value={0.0}
              style={{
                width: "100%",
                borderRadius: "16px",
                borderColor: "#0073e6",
                backgroundColor: "#ffffff",
              }}
            />
          </Col>
          <Col span={6}>
            <Text> PPC </Text>
            <InputNumber
              value={ppc}
              onChange={setPpc}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              min={0}
              max={100}
              style={{
                width: "100%",
                borderRadius: "16px",
                borderColor: "#0073e6",
                backgroundColor: "#ffffff",
              }}
            />
          </Col>
          <Col span={6}>
            <Text> COG </Text>
            <InputNumber
              value={14.1}
              style={{
                width: "100%",
                borderRadius: "16px",
                borderColor: "#0073e6",
                backgroundColor: "#ffffff",
              }}
            />
          </Col>
          <Col span={6}>
            <Text> Storage </Text>
            <InputNumber
              value={3.6}
              style={{
                width: "100%",
                borderRadius: "16px",
                borderColor: "#0073e6",
                backgroundColor: "#ffffff",
              }}
            />
          </Col>
        </Row>

        <Divider className="my-2" />

        {/* Changes Section */}
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={12}>
            <Text> Selling price not changed </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>0€ -</Text>
          </Col>
          <Col span={12}>
            <Text>PPC reduced to {ppc}%</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>
              <ArrowUpOutlined style={{ color: "green" }} /> +{ppcSavings}€
            </Text>
          </Col>
          <Col span={12}>
            <Text>COG not changed</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>0€ -</Text>
          </Col>
          <Col span={12}>
            <Text>Storage not changed</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text>0€ -</Text>
          </Col>
        </Row>

        <Divider className="my-2" />

        {/* Profit Section */}
        <Row className="pb-3" style={{ marginTop: 30 }}>
          <Col span={12}>
            <Text
              style={{ fontSize: 18, fontWeight: "bold", color: "#004080" }}
            >
              Monthly profit right now: {monthlyProfit}€
            </Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text
              style={{ fontSize: 18, fontWeight: "bold", color: "#52c41a" }}
            >
              {goalProfit}€
            </Text>
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
};

export default Goal;
